<style>
/* Underline From Center */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #b91c1c;
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}
</style>

<template>
  <main class="h-screen max-w-screen text-white overflow-hidden">
    <BackgroundVideo />

    <section class="flex flex-col w-screen h-screen z-10">
      <nav class="flex flex-row items-center place-content-center lg:justify-between w-screen px-4 bg-black opacity-80 py-2 lg:py-4">
        <h1 class="text-2xl lg:text-4xl py-1 font-serif text-red-700 font-bold">
          <router-link to="/" class="hvr-underline-from-center">
            NYUMBAKUMI
          </router-link>
        </h1>
  
        <button class="fixed lg:relative top-[50%] lg:top-0 left-4 lg:left-0 rotate-90 lg:rotate-0 text-black bg-white lg:bg-transparent opacity-80 lg:opacity-100 lg:text-white flex flex-row lg:mr-4 space-x-2 border border-x-0 rounded-xl border-red-700 p-2 hover:text-red-700 hover:border-x-2 font-bold lg:font-semibold">
          <a href="https://play.google.com/store/apps/details?id=com.erichydev.nyumbakumi" target="_blank">
            GET APP
          </a>
        </button>
      </nav>
    </section>

    <FooterVue />
  </main>
</template>

<script lang="ts" setup>
import BackgroundVideo from '@/components/BackgroundVideo.vue';
import FooterVue from '@/components/FooterVue.vue';
document.title = 'Nyumba Kumi - Simplifying the search for rental properties';
</script>
