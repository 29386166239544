<script lang="ts" setup></script>

<template>
  <main class="fixed bottom-0 w-screen py-2 bg-black opacity-80 flex flex-row space-x-2 items-center place-content-center text-xs md:text-base lg:text-lg">
    <p class="text-xs underline text-red-500 absolute right-0 mr-1">
      @2024
    </p>
    <router-link 
      to="/privacyPolicy" 
      class="hvr-underline-from-center hover:text-red-700 border border-x-0 rounded-xl border-red-700 p-2 hover:border-x-2"
    >
      Privacy Policy
    </router-link>
    <router-link 
      to="/termsAndConditions" 
      class="hvr-underline-from-center border border-x-0 rounded-xl border-red-700 p-2 hover:text-red-700 hover:border-x-2"
    >
      Terms & Conditions
    </router-link>
  </main>
</template>