<script lang="ts" setup>
import { onUnmounted, ref } from 'vue';
import { Cloudinary } from '@cloudinary/url-gen';
import { source } from '@cloudinary/url-gen/actions/overlay';
import { trim } from '@cloudinary/url-gen/actions/videoEdit';
import { Position } from '@cloudinary/url-gen/qualifiers';
import { compass } from '@cloudinary/url-gen/qualifiers/gravity';
import { subtitles } from '@cloudinary/url-gen/qualifiers/source';
import { AdvancedVideo } from '@cloudinary/vue';
import { byAngle } from '@cloudinary/url-gen/actions/rotate';

const cld = new Cloudinary({
  cloud: {
    cloudName: 'dgaqws2ux'
  }
});

const homeVid = cld
  .video('nyumba_kumi/3D_House_Animation_720pFH_eosopb')
  .videoEdit(trim().startOffset(6).endOffset(74))
  .overlay(
    source(
      subtitles('nyumba_kumi/house_r8cdc.srt')
    ).position(
      new Position()
        .gravity(compass('center'))
    )
  )
const homeVidCopy = cld
  .video('nyumba_kumi/3D_House_Animation_720pFH_eosopb')
  .videoEdit(trim().startOffset(6).endOffset(74))
  .overlay(
    source(
      subtitles('nyumba_kumi/house_r8cdc.srt')
    ).position(
      new Position()
        .gravity(compass('center'))
    )
  )

if(window.innerWidth < 768) {
  homeVid.rotate(byAngle(90));
  homeVidCopy.rotate(byAngle(90));
}
const vids = [homeVid, homeVidCopy];
let j = 0;
const currentVid = ref(homeVid);

const homeId = setInterval(() => {
  currentVid.value = vids[j];
  j = (j + 1) % vids.length;
}, 34.2 * 1000);

onUnmounted(() => {
  clearInterval(homeId);
})
</script>

<template>
  <AdvancedVideo
    :cld-vid=currentVid
    autoplay
    muted
    class="w-screen h-screen object-cover fixed"
  />
</template>